/**
 * KKH Sepamandat
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankDetailsBvs } from './bank-details';
import { PersonBvs } from './person';

/**
 * Sepa-Mandat
 */
export interface SepaBvs {
  /**
   * Für welchen Zweck möchten Sie ein Lastschriftmandat erteilen?
   */
  purpose: SepaBvs.PurposeBvs;
  /**
   * Ab wann sollen die Beitragszahlungen abgezogen werden?
   */
  feeDate?: string;
  person: PersonBvs;
  bankDetails: BankDetailsBvs;
  /**
   * Versichertennummer
   */
  insuranceNumber: string;
}
export namespace SepaBvs {
  export type PurposeBvs = 'fee' | 'services' | 'deductible' | 'sickpay';
  export const PurposeBvs = {
    Fee: 'fee' as PurposeBvs,
    Services: 'services' as PurposeBvs,
    Deductible: 'deductible' as PurposeBvs,
    Sickpay: 'sickpay' as PurposeBvs,
  };
}
