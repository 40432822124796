/**
 * KKH Sepamandat
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressBvs } from './address';

/**
 * Kontoinhaber
 */
export interface AccountHolderBvs {
  /**
   * Anrede
   */
  salutation: AccountHolderBvs.SalutationBvs;
  /**
   * Nachname
   */
  familyName?: string;
  /**
   * Vorname
   */
  givenName?: string;
  /**
   * Name der Firma
   */
  companyName?: string;
  address: AddressBvs;
  nameGiven?: boolean;
}
export namespace AccountHolderBvs {
  export type SalutationBvs = 'diverse' | 'female' | 'male' | 'company';
  export const SalutationBvs = {
    Diverse: 'diverse' as SalutationBvs,
    Female: 'female' as SalutationBvs,
    Male: 'male' as SalutationBvs,
    Company: 'company' as SalutationBvs,
  };
}
