/**
 * KKH Sepamandat
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressBvs } from './address';

/**
 * Person
 */
export interface PersonBvs {
  /**
   * Anrede
   */
  salutation: PersonBvs.SalutationBvs;
  /**
   * Nachname
   */
  familyName: string;
  /**
   * Vorname
   */
  givenName: string;
  address: AddressBvs;
}
export namespace PersonBvs {
  export type SalutationBvs = 'diverse' | 'female' | 'male' | 'nonbinary';
  export const SalutationBvs = {
    Diverse: 'diverse' as SalutationBvs,
    Female: 'female' as SalutationBvs,
    Male: 'male' as SalutationBvs,
    Nonbinary: 'nonbinary' as SalutationBvs,
  };
}
