import { ModuleWithProviders, NgModule } from '@angular/core';
import { MkkEnvironment } from '@mkk/env';
import { BASE_PATH as BASE_PATH_BVS, BvsApiModule } from './schemas/bvs';
import { BASE_PATH as BASE_PATH_BITGO, BitgoApiModule } from './schemas/bitgo';

function generateApiPath(path: string, env?: MkkEnvironment) {
  return `${env?.apiUrl ?? ''}/${path}`;
}

@NgModule({
  imports: [BvsApiModule, BitgoApiModule],
})
export class SepaApiModule {
  public static forRoot(env?: MkkEnvironment): ModuleWithProviders<SepaApiModule> {
    return {
      ngModule: SepaApiModule,
      providers: [
        { provide: BASE_PATH_BVS, useFactory: () => generateApiPath('sepama/v1', env) },
        { provide: BASE_PATH_BITGO, useFactory: () => generateApiPath('sepama/v3', env) },
      ],
    };
  }
}
